<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <div>
          <b-card-group deck>
            <b-card header-tag="header" footer-tag="footer">
              <template v-slot:header>
                <h6 class="mb-0">Thống kê lịch sử phát quà</h6>
              </template>
              <div class="filter-transaction">
                <b-form @submit.prevent="search()" >
                  <div class="row mb-6">
                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Số điện thoại:</label>
                      <input
                          type="text"
                          class="form-control datatable-input"
                          placeholder="Nhập số điện thoại..."
                          v-model="paramFilter.phone"
                      />
                    </div>
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label class="typo__label">Chọn campaign</label>
                      <multiselect
                          v-model="select_event"
                          :options="events"
                          :custom-label="nameWithLang"
                          placeholder="Nhập tên hoặc Campaign code"
                          label="name"
                          @input	="setCombos()"
                          track-by="id">

                      </multiselect>
                    </div>
<!--                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Chọn campaign:</label>
                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.event_id"
                          @change="setCombos()"
                      >
                        <option value="">&#45;&#45;Chọn&#45;&#45;</option>
                        <option v-for="(item, index) in events" :value="item.id" :key="index">{{ item.name }}</option>
                      </select>
                    </div>-->
                    <div class="col-lg-3 mb-lg-0 mb-6" v-if="combos.length > 0">
                      <label>Chọn combo:</label>
<!--                      <select
                          class="form-control datatable-input"
                          data-col-index="7"
                          v-model="paramFilter.combo_voucher_id"

                      >
                        <option value="">&#45;&#45;Chọn&#45;&#45;</option>
                        <option v-for="(item, index) in combos" :value="item.id" :key="index">{{ item.title }}</option>
                      </select>-->

                      <multiselect
                          v-model="select_combos"
                          :options="combos"
                          :multiple="true"
                          placeholder="Nhập tên combo"
                          label="title"
                          track-by="id">

                      </multiselect>
                    </div>

<!--                    <div class="col-lg-2 mb-lg-0 mb-6">
                      <label>Từ ngày :</label>
                      <div class="input-daterange input-group" id="kt_datepicker">
                        <input
                            type="date"
                            v-model="paramFilter.date"
                            class="form-control datatable-input"
                            name="start"
                            placeholder="Từ"
                        />
                      </div>
                    </div>-->
                    <div class="col-lg-3 mb-lg-0 mb-6">
                      <label>Chọn khoảng ngày:</label><br />
                      <date-picker v-model="date_range" type="date" range placeholder="Chọn khoảng ngày"></date-picker>
                    </div>
                    <div class="col-lg-1 ">
                      <label>.</label><br />
                      <button
                          class="btn btn-primary btn-primary--icon"
                      >
                      <span>
                        <span>Tìm kiếm</span>
                      </span>
                      </button>

                    </div>
                    <div class="col-lg-1 ">
                      <label>.</label><br />
                      <button class="btn btn-primary btn-primary--icon" @click="exportList()">
                        <i class="la la-cloud-download"></i>
                        <span>Xuất file</span>
                      </button>
                    </div>
                    <div class="col-lg-1 ">
                      <label>.</label><br />
                      <button class="btn btn-default" @click="clearFilter()">
                        <span>Xóa lọc</span>
                      </button>
                    </div>

                  </div>

                </b-form>
              </div>

              <b-table
                  table-class="datatable-table"
                  hover
                  tbody-tr-class="datatable-row"
                  thead-tr-class="datatable-row"
                  thead-class="datatable-head"
                  details-td-class="datatable-cell"
                  :items="items"
                  :fields="fieldsLog"
              >
                <template #cell(user_info)="data">
                  <span> SĐT : {{ data.item.phone }} </span> <br />
                  <span> User ID: {{ data.item.uuid }} </span><br />
                  <span v-if="data.item.name">
                    họ tên: {{ data.item.name }}
                  </span>
                </template>

                <template #cell(status)="data">
                  <span class="label font-weight-bold label-lg label-inline label-light-info" v-if="data.item.status == 0"> pending </span>
                  <span class="label font-weight-bold label-lg label-inline label-light-success" v-else-if="data.item.status == 1"> success </span>
                </template>
              </b-table>
              <div class="mt-3">
                <b-pagination
                    v-model="currentPage"
                    :link-gen="createLinkPage"
                    :total-rows="paginate.total"
                    :per-page="paginate.per_page"
                    use-router
                    first-number
                    last-number
                    align="right"
                ></b-pagination>
              </div>
            </b-card>
          </b-card-group>
        </div>
      </div>
    </div>
    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";
import Multiselect from 'vue-multiselect'
import AlertDialogue from "../../components/AlertModal";
const WalletCampaignRepository = RepositoryFactory.get("comboVoucher");
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    Multiselect,AlertDialogue,DatePicker
  },
  mixins: [Common],
  data() {
    return {
      paramFilter: {
        phone: "",
        event_id: "",
        event: "",
        combo_voucher_id: [],
        from_date: '',
        to_date: '',
        limit: 20,
      },
      date_range:[],
      select_event:'',
      select_combos:[],
      fieldsLog: [
        {
          key: "id",
          label: "ID",
        },
        {
          key: "event_code",
          label: "campaign code",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "event_name",
          label: "sự kiện",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "user_info",
          label: "Thông tin user",
          tdClass: "datatable-cell set-width",
          thClass: "datatable-cell",
        },
        {
          key: "combo_name",
          label: "Tên combo voucher",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "status",
          label: "Trạng thái",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
        {
          key: "created_at",
          label: "Thời gian",
          tdClass: "datatable-cell",
          thClass: "datatable-cell",
        },
      ],
      events: [],
      combos: [],
      items: [],
      currentPage: 1,
      paginate: {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
    };
  },
  created() {
    this.loadEvent();
    this.loadData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Thống kê" },
      { title: "Thống kê lịch sử phát quà", route: "/combo-voucher/report/log-user-gifts" },
    ]);
  },
  watch: {
    currentPage() {
      this.loadData();
    },
  },
  methods: {
    nameWithLang ({ name, code }) {
      return `${name} — ${code}`
    },
    clearFilter(){
      this.paramFilter = {
        phone: "",
        event_id: "",
        event: "",
        combo_voucher_id: [],
        from_date: '',
        to_date: '',
      };
      this.select_combos=[]
      this.select_event='';
      this.date_range = [];
    },

    exportList: async function () {
      this.$bus.$emit("show-loading", true);
      this.paramFilter.page = this.currentPage;
      this.paramFilter.combo_voucher_id = [];
      if (this.date_range.length > 0){
        this.paramFilter.from_date = this.date_range[0];
        this.paramFilter.to_date = this.date_range[1];
      }
      if (this.select_combos){
        let comboIds = [];
        this.select_combos.forEach(item=>{
          comboIds.push(item.id)
        })
        this.paramFilter.combo_voucher_id = comboIds.toString()
      }
      let response = await WalletCampaignRepository.exportReportLogUserGifts(this.paramFilter);
      this.$bus.$emit("show-loading", false);

      if (response.data.error_code !== 0) {
        alert("Có lỗi xảy ra, vui lòng thử lại");
        return false;
      }
      let link = window.location.origin + "/#/reconcile/export-data";
      const cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='"+link+"' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });
      if (!cfm) {
        return false;
      }
    },

    setCombos(){
      if (this.select_event){
        this.combos =[];
        this.paramFilter.event_id =  this.select_event.id;
        let event =  this.events.filter(event => event.id === this.paramFilter.event_id)[0];
        this.combos = event.combos
        this.select_combos = []
      }else {
        this.combos =[];
      }

    },
    async loadData() {
      try {
        this.$bus.$emit("show-loading", true);
        this.paramFilter.page = this.currentPage;
        this.paramFilter.combo_voucher_id = [];
        if (this.date_range.length > 0){
          this.paramFilter.from_date = this.date_range[0];
          this.paramFilter.to_date = this.date_range[1];
        }
        if (this.select_combos){
          let comboIds = [];
          this.select_combos.forEach(item=>{
            comboIds.push(item.id)
          })
          this.paramFilter.combo_voucher_id = comboIds.toString()
        }
        let response = await WalletCampaignRepository.reportLogUserGifts(this.paramFilter);
        this.$bus.$emit("show-loading", false);
        let body = response.data.data;
        this.items = body.items;
        this.paginate = body.meta;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
    async search () {
      this.currentPage = 1;
      this.paginate = {
        limit: 20,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      }
      //this.$router.push({path: this.$route.fullPath, query: {page: this.currentPage} });
      this.loadData();
    },
    async loadEvent() {
      try {
        let response = await WalletCampaignRepository.getEvents({
          is_show_all: true
        });
        this.events = response.data.data;
      } catch (error) {
        this.$bus.$emit("show-loading", false);
      }
    },
  },
};
</script>

<style>
.set-width {
  width: 30% !important;
}
select.form-control { -webkit-appearance: menulist; }
.mx-datepicker-range{
  width: unset !important;
}
.mx-input{
  height: 40px !important;

}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>